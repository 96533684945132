import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { GlobalStateProvider, useGlobalState } from 'Store/state';
import { basename } from 'Constants/basename';
import ErrorBoundary from 'Components/ErrorBoundary';
import { IntlProviderWithMessages } from 'Components/IntlProviderWithMessages';
import IconsWrapperProvider from 'Components/IconsWrapperProvider';
import routes from './routes';
import { loginService } from "./services";
import { createBrowserHistory } from "history";

const RenderRoute = route => {
  const [isLoggedIn] = useGlobalState('isLoggedIn');
  const [locale] = useGlobalState('locale');
  const token = Cookies.get('token');

  return (
    <Route
      exact
      path={route.path}
      render={props => {
          if (!!route.private && !isLoggedIn && token) {
            loginService().loadUserFromTokenData(JSON.parse(token))
              .catch(() => props.history.replace('/login'));
          } else if (!!route.private && !isLoggedIn) {
            props.history.replace('/login')
        } else {
          return (
            <ErrorBoundary>
              <route.component {...props} content={route.content} locale={locale} />
            </ErrorBoundary>
          );
        }
      }}
    />
  );
};

RenderRoute.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

const App = () => {
  const history = createBrowserHistory();
  const { hash, pathname, search } = history.location;

  if (!!search && !!hash) {
    history.push(`${pathname}${hash}`);
  }

  return (
    <div className="app">
      <GlobalStateProvider>
        <IntlProviderWithMessages>
          <IconsWrapperProvider>
            <Router basename={basename}>
              <Switch>
                {routes.map((route, i) => <RenderRoute key={i} {...route} />)}
                <Route render={() => <Redirect to="/login" />} />
              </Switch>
            </Router>
          </IconsWrapperProvider>
        </IntlProviderWithMessages>
      </GlobalStateProvider>
    </div>
  );
};

export default App;
