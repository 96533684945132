import loadable from '@loadable/component';

const routes = [
  {
    path: '/login',
    component: loadable(() => import('Components/Login')),
    content: {
      title: 'login.content-title',
      description: 'login.content-description',
    },
  },
  {
    path: '/invitations/:token/accept',
    component: loadable(() => import('Components/Signup')),
    content: {
      title: 'create.account',
      description: 'create.account.content-description',
    },
  },
  {
    path: '/invitations/:token/decline',
    component: loadable(() => import('Components/DeclineInvitation')),
    content: {
      title: 'invitation.decline.title',
      description: 'invitation.decline.description',
    },
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('Components/ForgotPassword')),
    content: {
      title: 'forgot.password',
      description: 'forgot.password.content-description',
      f99: [1,2],
    },
  },
  {
    path: '/resetPassword/:token',
    component: loadable(() => import('Components/ResetPassword')),
    content: {
      title: 'reset.password',
      description: 'reset.password.content-description',
    },
  },
  {
    path: '/profile',
    component: loadable(() => import('Components/Profile')),
    private: true,
    content: {
      title: 'profile.heading',
      description: 'profile.body',
    },
  },
  {
    path: '/companies/:id/accounts',
    component: loadable(() => import('Components/Accounts')),
    private: true,
    content: {
      title: 'accounts.heading',
      description: 'accounts.body',
    },
  },
  {
    path: '/reloadUser',
    component: loadable(() => import('Components/ReloadUser')),
    content: {
      title: 'login.content-title',
      description: 'login.content-description',
    },
  },
];

export default routes;
