import api from './api';

/**
 *
 * @param {string} _username
 * @param {string} _password
 */
export function authenticate(_username, _password) {
  return api.post('/auth/oauth/login_check', {
    _username,
    _password,
    _target_path: '/auth/token',
  });
}

export function logout() {
  return Promise.resolve();
}

/**
 * Get information to impersonate a user
 * @returns {Promise<AxiosResponse<T>>}
 */
export function impersonateUser() {
  return api.get('/auth/reload-user');
}
