import api from './api';

/**
 *
 * @param {string} emailAddress
 */
export function requestResetPassword(emailAddress) {
  return api.post('/auth/request-password-reset', {
    request_password_reset: { emailAddress },
  });
}
