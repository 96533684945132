import { createGlobalState } from 'react-hooks-global-state';
import Cookies from "js-cookie";

import { config } from 'Constants/config';

const initialState = {
  isLoggedIn: false,
  accessToken: '',
  accountId: '',
  name: '',
  emailAddress: '',
  hasWeakPassword: false,
  companies: [],
  locale: 'en-US',
  hasOldPassword: true,
  firstName: '',
  lastName: '',
};

const { GlobalStateProvider, setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setLocale = s => {
  const localeMap = {
    en: 'en-US',
    en_US: 'en-US',
    'en-US': 'en-US',
    de: 'de-DE',
    de_DE: 'de-DE',
    'de-DE': 'de-DE',
    nl: 'nl-NL',
    nl_NL: 'nl-NL',
    'nl-NL': 'nl-NL',
  };

  setGlobalState('locale', localeMap[s]);
};

export const loadTokenData = data => {
  setGlobalState('isLoggedIn', true);
  setGlobalState('accessToken', data.accessToken);
  setGlobalState('accountId', data.accountId);
  setGlobalState('hasOldPassword', data.hasOldPassword)
  setGlobalState('hasWeakPassword', data.hasWeakPassword);
};

export const loadAccountData = data => {
  const { firstName, lastName, emailAddress, companies } = data;
  const activeCompanies = companies && companies.filter(company => {
    return company.apps.find(a => a.appId === config.WEBSHOP_APP_ID);
  });

  setGlobalState('name', `${firstName} ${lastName}`);
  setGlobalState('firstName', firstName);
  setGlobalState('lastName', lastName);
  setGlobalState('emailAddress', emailAddress);
  setLocale(data.locale);
  setGlobalState('companies', activeCompanies || []);
};

export const logout = () => {
  Cookies.remove('token');
  setGlobalState('isLoggedIn', false);
  setGlobalState('accessToken', '');
};

export const updateStateRecords = obj => {
  Object.keys(obj).forEach(key => {
    if (key === 'name') {
      const name = obj[key];
      setGlobalState('name', `${name.firstName} ${name.lastName}`)
      setGlobalState('firstName', name.firstName)
      setGlobalState('lastName', name.lastName)
    } else if (key.toLowerCase().match(/password/g) === null) {
      setGlobalState(key, obj[key]);
    }
  });
};

export { GlobalStateProvider, useGlobalState, setGlobalState, getGlobalState };
