import api from './api';
import axios from 'axios';
import { updateStateRecords } from 'Store/state';

/**
 *
 * @param {string} accountId
 * @param {string} token
 */
export function accounts(accountId, token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.get(`/accounts/${accountId}`);
}

/**
 *
 * @param {object} params
 * @param {string} id
 */
export async function createAccount (params, id){
  const revertLocale = s => {
    const localeMap = {
      en: 'en_US',
      en_US: 'en_US',
      'en-US': 'en_US',
      de: 'de_DE',
      de_DE: 'de_DE',
      'de-DE': 'de_DE',
      nl: 'nl_NL',
      nl_NL: 'nl_NL',
      'nl-NL': 'nl_NL',
    };

    return localeMap[s];
  };
  const locale = revertLocale(params.locale);

  if (locale) {
    params.locale = locale;
  }

  return await api.post(`/invitations/${id}`, { accept_invite: params });
}

/**
 *
 * @param {string} id
 */
export const declineInvitation = id => {
  return api.post(`/invitations/${id}`, { decline_invite: {} });
}

/**
 *
 * @param {string} id
 */
export function findInvitation(id) {
  return api.get(`/invitations/${id}`);
}

/**
  *
  * @param {string} id
  * @param {string} accountId
  * @param {string} token
  */
export function accountAcceptInvitation(id, accountId, token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post(`/invitations/${id}`, { accept_invite_by_account: { accountId }});
}

/**
 *
 * @param {string} companyId
 * @param {string} token
 */
export function companyService(companyId, token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.get(`/companies/${companyId}`);
}

/**
 *
 * @param {string} accountId
 * @param {string} token
 * @param {string} password
 */
export function resetPassword(accountId, token, password) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post(`/accounts/${accountId}/credentials`, { reset_password: { password }});
}

/**
 *
 * @param {string} accountId
 * @param {string} accessToken
 * @param {object} values
 */
export async function updateAccount(accountId, accessToken, values) {
  const commands = {
    emailAddress: emailAddress => {
      return {
        action: 'change_email_address',
        value: { emailAddress },
      };
    },
    locale: rawLocale => {
      const locale = rawLocale.replace(/-/g, '_');
      return {
        action: 'change_preferred_locale',
        value: { locale },
      };
    },
    name: (firstName, lastName) => {
      return {
        action: 'change_name',
        value: { firstName, lastName },
      };
    },
    password: (currentPassword, newPassword) => {
      return {
        action: 'change_password',
        value: { currentPassword, newPassword },
        suffix: 'credentials',
      };
    },
  };

  const calls = [];
  const passwords = {};
  const names = {};

  Object.keys(values).forEach(key => {
    const value = values[key];

    if (key.toLowerCase().match(/password/g) !== null) {
      passwords[key] = value;
    } else if (key.toLowerCase().match(/name/g)) {
      names[key.split('.').pop()] = value;
    } else {
      calls.push(commands[key](value));
    }
  });

  if (Object.keys(names).length > 0) {
    const { firstName, lastName } = names.name;
    const cmd = commands['name'](firstName, lastName);
    calls.push(cmd);
  }

  if (Object.keys(passwords).length > 0) {
    const { currentPassword, newPassword } = passwords;
    const cmd = commands['password'](currentPassword, newPassword);
    calls.push(cmd);
  }

  async function postCommand(calls) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    const requests = [];

    for (let x = 0; x < calls.length; x++) {
      const { action, value, suffix } = calls[x];
      const postData = {};
      const urlSuffix = suffix ? `/${suffix}` : '';

      postData[action] = value;
      requests.push(api.post(`/accounts/${accountId}${urlSuffix}`, postData))
    }

    return await axios.all(requests).then(axios.spread(() => updateStateRecords(values)));
  }

  await postCommand(calls);
}
