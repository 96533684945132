import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

const initialState = {
  fadeIn: false,
};

const reducer = (state, action) => {
  switch(action.type) {
    case 'FADE_IN':
      return {
        ...state,
        fadeIn: true,
      };
    default:
      return state;
  }
};

export const IconsWrapperContext = React.createContext(initialState);

export const IconsWrapperConsumer = IconsWrapperContext.Consumer;

const IconsWrapperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return (
    <IconsWrapperContext.Provider value={value}>
      {children}
    </IconsWrapperContext.Provider>
  );
}

IconsWrapperProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconsWrapperProvider;
