import Cookies from 'js-cookie';
import { authenticate, impersonateUser } from './auth';
import { accounts } from './account';
import { loadTokenData, loadAccountData, logout } from 'Store/state';

export function loginService() {
  function loginError(error) {
    Cookies.remove('token');
    throw Error(error);
  }

  function login(_username, _password) {
      const loginSuccess = function(res) {
        const { data, status, statusText } = res;

        if (status === 200) {
          Cookies.set('token', data, { expires: 365 });
          return loadUserFromTokenData(data);
        } else {
          loginError(statusText);
        }
      };

      return authenticate(_username, _password)
        .then(res => loginSuccess(res))
        .catch((err) => loginError(err));
  }

  function loadUserFromTokenData(tokenData) {
      const { accountId, accessToken } = tokenData;

      loadTokenData(tokenData);

      return accounts(accountId, accessToken)
        .then(data => loadAccountData(data.data))
        .catch(error => loginError(error));
  }

  const reloadUser = async () => {
    logout();
    return await impersonateUser()
      .then(({ data }) => {
        loadUserFromTokenData(data);
        Cookies.set('token', data, { expires: 365 });
      })
      .catch(error => loginError(error));
  }

  return { login, loadUserFromTokenData, reloadUser };
}
