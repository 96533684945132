import React, { useEffect, useState } from 'react';
import {createIntl, createIntlCache, RawIntlProvider} from 'react-intl'
import PropTypes from 'prop-types';
import { useGlobalState } from 'Store/state';

const IntlProviderWithMessages = ({ children }) => {
  const [locale] = useGlobalState('locale');
  const [error, setError] = useState(false);
  const [messages, setMessages] = useState({});

  const loadMessages = locale => {
    import(/* webpackChunkName: "[request]" */ `../intl/messages.${locale}.json`)
      .then(messages => {setMessages({ messages: messages.default })})
      .catch(error => setError({ error }));
  }

  useEffect(() => {
    loadMessages(locale);
  }, [locale]);

  const cache = createIntlCache();
  const intl = createIntl({
    locale,
    messages: messages ? messages.messages : {},
  }, cache);

  if (error) {
    return (
      <div className="alert alert-danger">
        <strong>This application failed to load:</strong> {error.message}
      </div>
    );
  }

  return (
    messages ? (
      <RawIntlProvider value={intl}>
        {children}
      </RawIntlProvider>
    ) : null
  );
}

IntlProviderWithMessages.propTypes = {
  children: PropTypes.node.isRequired,
};

export { IntlProviderWithMessages };
